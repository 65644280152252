import React, { useState } from "react";
import Frame from "../components/common/Frame";

const Healthcare = () => {
  const subMenu = [
    { title: "혈압", content: <div>혈압 내용</div> },
    { title: "체온", content: <div>체온 내용</div> },
    { title: "체중(BMI)", content: <div>체중(BMI) 내용</div> },
    { title: "심전도", content: <div>심전도 내용</div> },
    {
      title: "헬스케어 기기 동작현황",
      content: <div>헬스케어 기기 동작현황 내용</div>,
    },
    { title: "이용량", content: <div>이용량 내용</div> },
  ];

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  return (
    <Frame
      title={"헬스케어 시스템 운영 관리"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default Healthcare;
