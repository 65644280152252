import React from "react";
import Frame from "../components/common/Frame";
import { Col, Form, Row } from "react-bootstrap";

const Login = () => {
  return (
    <Frame title={"로그인"} showTabMenu={false} className={"my-auto"}>
      <Row
        className="w-50 border rounded px-3 py-5 justify-content-center"
        style={{ backgroundColor: "#1D222A" }}
      >
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              Email
            </Form.Label>
            <Col sm="10">
              <Form.Control type="email" placeholder="email@example.com" />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextPassword"
          >
            <Form.Label column sm="2">
              Password
            </Form.Label>
            <Col sm="10">
              <Form.Control type="password" placeholder="Password" />
            </Col>
          </Form.Group>
        </Form>

        <Col className="mt-4">
          <button className="btn btn-primary btn-lg w-100">로그인</button>
        </Col>
      </Row>
    </Frame>
  );
};

export default Login;
