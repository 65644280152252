import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./Header";
import TabMenu from "./TabMenu";
import BackgroundImg from "../../assets/landing/bg.png";

const Frame = ({
  title,
  children,
  className,
  selectedTab,
  showTabMenu,
  onTabSelect,
  subMenu,
}) => {
  const [activeTab, setActiveTab] = useState("");

  return (
    <Container
      fluid
      className={"container-fluid landing container-wrap p-0"}
      style={{
        height: "100vh",
        backgroundImage: `url(${BackgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        objectFit: "cover",
        overflowX: "hidden ",
      }}
    >
      <Header title={title} />
      {showTabMenu && (
        <TabMenu
          subMenu={subMenu}
          activeTab={selectedTab}
          onTabSelect={(tab) => {
            setActiveTab(tab); // Frame 내에서 상태 업데이트
            if (onTabSelect) onTabSelect(tab); // 부모 컴포넌트에 콜백 전달
          }}
        />
      )}
      <Row className={`px-md-4 px-lg-5 justify-content-center ${className}`}>
        {children}
      </Row>
    </Container>
  );
};

export default Frame;
