import React, { useState } from "react";
import Frame from "../components/common/Frame";

const Lifestyle = () => {
  const subMenu = [
    {
      title: "워킹머신 대회 데이터관리",
      content: <div>워킹머신 대회 데이터관리 내용</div>,
    },
    {
      title: "워킹머신 대회 히스토리 확인",
      content: <div>워킹머신 대회 히스토리 확인 내용</div>,
    },
    {
      title: "워킹머신 대전 데이터관리",
      content: <div>워킹머신 대전 데이터관리 내용</div>,
    },
    {
      title: "워킹머신 코스별 랭킹",
      content: <div>워킹머신 코스별 랭킹 내용</div>,
    },
    {
      title: "스마트테이블 콘텐츠별 랭킹",
      content: <div>스마트테이블 콘텐츠별 랭킹 내용</div>,
    },
    {
      title: "생활케어 기기 동작 현황",
      content: <div>생활케어 기기 동작 현황 내용</div>,
    },
    { title: "이용량", content: <div>이용량 내용</div> },
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  return (
    <Frame
      title={"생활케어 시스템 운영 관리"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default Lifestyle;
