import React, { useState } from "react";
import Frame from "../components/common/Frame";

const User = () => {
  const subMenu = [
    { title: "관리자 로그 관리", content: <div>관리자 로그 관리 내용</div> },
    { title: "관리자 계정 관리", content: <div>관리자 계정 관리 내용</div> },
    { title: "경로당 계정 관리", content: <div>경로당 계정 관리 내용</div> },
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  return (
    <Frame
      title={"사용자 관리"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default User;
