import React, { useState } from "react";
import RealTimeMonitoringImg from "../assets/test/realTimeMonitoring.jpg";
import { Container } from "react-bootstrap";
import BasicBarChart from "../components/chart/BasicBarChart";
import BasicColumnChart from "../components/chart/BasicColumnChart";
import LineChart from "../components/chart/LineChart";
import PieChart from "../components/chart/PieChart";
import TableTest from "../components/table/TableTest";
import Pagination from "react-bootstrap/Pagination";
import PaginatedTable from "../components/table/PaginatedTable";
import Frame from "../components/common/Frame";

const Monitoring = () => {
  const barChartData = {
    title: "Basic Bar Chart",
    xAxisCategories: ["A", "B", "C"],
    series: [{ type: "bar", name: "Data", data: [10, 20, 30] }],
    yAxisTitle: "Values",
  };

  const columnChartData = {
    title: "Basic Column Chart",
    xAxisCategories: ["X", "Y", "Z"],
    series: [{ type: "column", name: "Scores", data: [70, 85, 90] }],
    yAxisTitle: "Scores",
  };

  const lineChartData = {
    title: "Line Chart",
    xAxisCategories: ["Jan", "Feb", "Mar"],
    series: [{ type: "line", name: "Growth", data: [15, 25, 35] }],
    yAxisTitle: "Percentage",
  };

  const pieChartData = {
    title: "Donut Chart",
    series: [
      {
        type: "pie",
        name: "Share",
        data: [
          { name: "Chrome", y: 61.41 },
          { name: "Edge", y: 20.84 },
          { name: "Firefox", y: 17.75 },
        ],
      },
    ],
  };

  const columns = [
    { key: "name", title: "Name" },
    { key: "age", title: "Age" },
    {
      key: "position",
      title: "Position",
      render: (value) => <span style={{ fontStyle: "italic" }}>{value}</span>,
    },
    {
      key: "department",
      title: "Department",
      render: (value, row) => (
        <span>
          {value} ({row.position})
        </span>
      ),
    },
    {
      key: "actions",
      title: "Actions",
      render: (_, row) => (
        <button onClick={() => alert(`Editing ${row.name}`)}>Edit</button>
      ),
    },
  ];

  const dummyData = [
    {
      id: 1,
      name: "John Doe",
      age: 28,
      position: "Developer",
      department: "Engineering",
    },
    {
      id: 2,
      name: "Jane Smith",
      age: 34,
      position: "Designer",
      department: "Creative",
    },
    {
      id: 3,
      name: "Sam Wilson",
      age: 23,
      position: "Intern",
      department: "Marketing",
    },
    {
      id: 4,
      name: "Emma Brown",
      age: 40,
      position: "Manager",
      department: "HR",
    },
    {
      id: 5,
      name: "Michael Lee",
      age: 29,
      position: "Developer",
      department: "Engineering",
    },
    {
      id: 6,
      name: "Sophia Davis",
      age: 31,
      position: "Analyst",
      department: "Finance",
    },
    {
      id: 7,
      name: "James Taylor",
      age: 45,
      position: "Director",
      department: "Operations",
    },
    {
      id: 8,
      name: "Olivia Harris",
      age: 27,
      position: "Copywriter",
      department: "Marketing",
    },
    {
      id: 9,
      name: "Liam King",
      age: 32,
      position: "Engineer",
      department: "Engineering",
    },
    {
      id: 10,
      name: "Mia Allen",
      age: 36,
      position: "Consultant",
      department: "HR",
    },
  ];

  const subMenu = [
    {
      title: "종합 대시보드",
      content: (
        <div>
          <h1>User Table</h1>
          <PaginatedTable columns={columns} data={dummyData} pageSize={5} />
        </div>
      ),
    },
    { title: "경로당별 이용현황", content: <div>경로당별 이용현황 내용</div> },
    { title: "시스템별 이용현황", content: <div>시스템별 이용현황 내용</div> },
    {
      title: "이슈 사항 발생 현황",
      content: <div>이슈 사항 발생 현황 내용</div>,
    },
    {
      title: "셋톱박스 동작 현황",
      content: <div>셋톱박스 동작 현황 내용</div>,
    },
    {
      title: "헬스케어 동작 현황",
      content: <div>헬스케어 동작 현황 내용</div>,
    },
    {
      title: "생활케어 동작 현황",
      content: <div>생활케어 동작 현황 내용</div>,
    },
    {
      title: "헬프데스크 처리 현황",
      content: <div>헬프데스크 처리 현황 내용</div>,
    },
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  return (
    <Frame
      title={"실시간 모니터링"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {/* Chart */}
      {/*
        <h1>Highcharts Examples</h1>
        <BasicBarChart {...barChartData} />
        <BasicColumnChart {...columnChartData} />
        <LineChart {...lineChartData} />
        <PieChart {...pieChartData} />
        */}

      {renderContent()}
    </Frame>
  );
};

export default Monitoring;
