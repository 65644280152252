import React, { useState } from "react";
import { Table as BootstrapTable, Pagination } from "react-bootstrap";

const PaginatedTable = ({ columns, data, rowKey = "id", pageSize = 5 }) => {
  const [page, setPage] = useState(1);

  const paginatedData = data.slice((page - 1) * pageSize, page * pageSize);

  return (
    <div>
      <BootstrapTable striped hover responsive>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.key}>{col.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((row) => (
            <tr key={row[rowKey]}>
              {columns.map((col) => (
                <td key={col.key}>
                  {col.render ? col.render(row[col.key], row) : row[col.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </BootstrapTable>

      {/* 페이지네이션 */}
      <Pagination>
        {Array.from({ length: Math.ceil(data.length / pageSize) }).map(
          (_, i) => (
            <Pagination.Item
              key={i}
              active={i + 1 === page}
              onClick={() => setPage(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          )
        )}
      </Pagination>
    </div>
  );
};

export default PaginatedTable;
