// charts/BasicBarChart.jsx
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BasicBarChart = ({title, xAxisCategories, series, yAxisTitle}) => {
  const options = {
    chart: {type: 'bar'},
    title: {text: title},
    xAxis: {categories: xAxisCategories},
    yAxis: {title: {text: yAxisTitle}},
    series,
  };

  return <HighchartsReact highcharts={Highcharts} options={options}/>;
};

export default BasicBarChart;
