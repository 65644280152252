import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { postNotice } from "../api/apiClient";
import Frame from "../components/common/Frame";

const TemplateForm = () => {
  const [formData, setFormData] = useState({
    receiver: "", // 수신처 설정
    // selectedTemplate: '', // 템플릿 선택
    category: "1", // 카테고리 default (1: 공지사항)
    noticeContent: "", // 공지사항 내용
    repeatCount: 1, // 반복 횟수
    deliveryType: "reserve", // 예약 발송 or 바로 발송
    year: "2024",
    month: "1",
    day: "1",
    hour: "00",
    minute: "00",
  });

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const [daysInMonth, setDaysInMonth] = useState([]);

  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  useEffect(() => {
    const days = getDaysInMonth(formData.year, formData.month);
    setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
  }, [formData.year, formData.month]);

  // 업데이트 핸들러
  const updateField = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // 폼 유효성 검사
  const validateForm = () => {
    const requiredFields = [
      "receiver",
      "noticeContent",
      "repeatCount",
      "deliveryType",
      "year",
      "month",
      "day",
      "hour",
      "minute",
    ];
    for (let field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }
    return true;
  };

  // 발송 버튼 핸들러
  const handleSend = () => {
    if (!validateForm()) {
      alert("모든 필드를 채워주세요.");
      return;
    }

    let postData = {};
    if (formData.deliveryType === "reserve") {
      postData = {
        category: formData.category, // 카테고리 ID
        content: formData.noticeContent, // 공지사항 내용
        repeat_time: formData.repeatCount, // 반복 횟수
        is_reserved: formData.deliveryType === "reserve", // 예약 여부
        // reserved_at: `${formData.year}-${formData.month}-${formData.day}T${formData.hour}:${formData.minute}:00.000Z`,  // 예약 시간
        reserved_at: new Date(
          Date.UTC(
            formData.year,
            formData.month - 1,
            formData.day,
            formData.hour,
            formData.minute
          )
        ).toISOString(), // 예약 시간
        receiver: formData.receiver, // 수신처
      };

      // console.log("예약 발송 Post Data:", postData);
    } else if (formData.deliveryType === "immediate") {
      postData = {
        category: formData.category, // 카테고리 ID
        content: formData.noticeContent, // 공지사항 내용
        repeat_time: formData.repeatCount, // 반복 횟수
        is_reserved: formData.deliveryType === "immediate", // 예약 여부
        receiver: formData.receiver, // 수신처
      };

      // console.log("즉시 발송 Post Data:", postData);
    }

    console.log("Post Data:", postData);
    postNotice(postData)
      .then((res) => {
        console.log(res);
        alert("발송 완료");
      })
      .catch((err) => {
        console.error(err);
        alert("발송 실패");
      });
  };

  const subMenu = [
    {
      title: "공지사항 발송",
      content: (
        <Container
          style={{
            backgroundColor: "#222",
            padding: "20px",
            borderRadius: "10px",
            color: "white",
          }}
        >
          {/* 상단 버튼 그룹 */}
          <Row className="mb-3 justify-content-start">
            <Col xs={4} xl={2} className={"my-auto align-middle"}>
              <label>수신처 설정</label>
            </Col>
            <Col xs={8} xl={4} className={"my-auto align-middle"}>
              <Form.Control
                type="text"
                placeholder="선택한 경로당 명"
                value={formData.receiver}
                onChange={(e) => updateField("receiver", e.target.value)}
              />
            </Col>
          </Row>

          {/* 템플릿 선택 (임시 주석 처리) */}
          {/*<Row className="mb-3">
        <Col xs={4} xl={2} className={"my-auto align-middle"}>
          <label>템플릿 선택</label>
        </Col>
        <Col xs={8} xl={4} className={"my-auto align-middle"}>
          <Form.Select
              // disabled={true}
              value={formData.selectedTemplate}
              onChange={(e) => updateField('selectedTemplate', e.target.value)}
          >
            <option value="">템플릿 리스트</option>
            <option value="type1">템플릿 1</option>
            <option value="type2">템플릿 2</option>
          </Form.Select>
        </Col>
      </Row>*/}

          {/* 공지사항 내용 */}
          <Row className="mb-3">
            <Col xs={4} xl={2} className={"my-auto align-middle"}>
              <label>공지사항 내용</label>
            </Col>
            <Col xs={8} xl={6} className={"my-auto align-middle"}>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="내용을 입력하세요"
                value={formData.noticeContent}
                onChange={(e) => updateField("noticeContent", e.target.value)}
              />
            </Col>
          </Row>

          {/* 반복 횟수 */}
          <Row className="mb-3">
            <Col xs={4} xl={2} className={"my-auto align-middle"}>
              <label>반복 횟수</label>
            </Col>
            <Col xs={8} xl={4} className={"my-auto align-middle"}>
              <Form.Control
                type="number"
                placeholder=""
                value={formData.repeatCount}
                onChange={(e) => updateField("repeatCount", e.target.value)}
              />
            </Col>
          </Row>

          {/* 예약 발송 및 라디오 버튼 */}
          <Row className="mb-3">
            <Col xs={4} xl={2} className={"my-auto align-middle"}>
              <label>예약 발송</label>
            </Col>
            <Col xs={8} xl={4} className={"my-auto align-middle"}>
              <Row>
                <Col xs={6} xl={4}>
                  <Form.Check type="radio" id="check-api-reserve">
                    <Form.Check.Input
                      type="radio"
                      checked={formData.deliveryType === "reserve"}
                      onChange={(e) => updateField("deliveryType", "reserve")}
                    />
                    <Form.Check.Label
                      style={{ verticalAlign: "-webkit-baseline-middle" }}
                    >
                      예약하기
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col xs={6} xl={4}>
                  <Form.Check type="radio" id="check-api-immediate">
                    <Form.Check.Input
                      type="radio"
                      checked={formData.deliveryType === "immediate"}
                      onChange={(e) => updateField("deliveryType", "immediate")}
                    />
                    <Form.Check.Label
                      style={{ verticalAlign: "-webkit-baseline-middle" }}
                    >
                      바로 발송
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* 예약 발송 날짜 정보 */}
          {formData.deliveryType === "reserve" && (
            <Row className="mb-3">
              <Col xs={4} xl={2} className={"my-auto align-middle"}>
                예약 시간 설정
              </Col>
              <Col xs={8} xl={6} className={"my-auto align-middle"}>
                <Row className={"row-gap-3"}>
                  <Col xs={12} lg={4}>
                    <Form.Select
                      className={"w-100"}
                      value={formData.year}
                      onChange={(e) => updateField("year", e.target.value)}
                    >
                      <option value="2024">2024</option>
                      <option value="2023">2023</option>
                    </Form.Select>
                  </Col>
                  <Col xs={12} lg={4}>
                    <Form.Select
                      className={"w-100"}
                      value={formData.month}
                      onChange={(e) => updateField("month", e.target.value)}
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}월
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col xs={12} lg={4}>
                    <Form.Select
                      className={"w-100"}
                      value={formData.day}
                      onChange={(e) => updateField("day", e.target.value)}
                    >
                      {daysInMonth.map((day) => (
                        <option key={day} value={day}>
                          {day}일
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col xs={12} lg={4}>
                    <Form.Select
                      className={"w-100"}
                      value={formData.hour}
                      onChange={(e) => updateField("hour", e.target.value)}
                    >
                      {Array.from({ length: 24 }, (_, i) => (
                        <option key={i} value={i}>
                          {i}시
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col xs={12} lg={4}>
                    <Form.Select
                      className={"w-100"}
                      value={formData.minute}
                      onChange={(e) => updateField("minute", e.target.value)}
                    >
                      {Array.from({ length: 60 }, (_, i) => (
                        <option key={i} value={i}>
                          {i}분
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {/* 발송 버튼 */}
          <Row>
            <Col>
              <Button className="w-100" onClick={handleSend}>
                발송
              </Button>
            </Col>
          </Row>
        </Container>
      ),
    },
    {
      title: "공지사항 템플릿 설정",
      content: <div>공지사항 템플릿 설정 내용</div>,
    },
    {
      title: "공지사항 히스토리",
      content: <div>공지사항 히스토리 내용</div>,
    },
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  return (
    <Frame
      title={"공지사항 관리"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default TemplateForm;
