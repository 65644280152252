import { SWAGGER_BASE_URL } from "../config";
import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.withCredentials = true;

const axiosClient = axios.create({
  baseURL: SWAGGER_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // 'Access-Control-Allow-Origin': '*', // CORS 허용
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS' // CORS 허용
  },
});

export const axiosClientFormData = axios.create({
  baseURL: `${SWAGGER_BASE_URL}`,
  timeout: 10000,
});

const axiosClientPostFormData = (url, formData) => {
  let ret;
  ret = axiosClientFormData.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      // "Content-Type": "application/json",
      // "Authorization": `Bearer ${token}`,
    },
  });
  return ret;
};

export const postNotice = (formData) => {
  try {
    return axiosClientPostFormData("/notice/", formData);
  } catch (e) {
    console.error(e);
  }
};

export const postMessage = (formData) => {
  try {
    return axiosClientPostFormData("/message/", formData);
  } catch (e) {
    console.error(e);
  }
};
