import React, { useState } from "react";
import Frame from "../components/common/Frame";

const Issue = () => {
  const subMenu = [
    {
      title: "발생 이슈 사항 관리",
      content: <div>발생 이슈 사항 관리 내용</div>,
    },
    {
      title: "이슈 사항 시나리오 관리",
      content: <div>이슈 사항 시나리오 관리 내용</div>,
    },
    {
      title: "이슈 사항 발생 통계",
      content: <div>이슈 사항 발생 통계 내용</div>,
    },
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  return (
    <Frame
      title={"이슈사항 관리"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default Issue;
