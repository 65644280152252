import React, { useState } from "react";
import Frame from "../components/common/Frame";

const VideoConference = () => {
  const subMenu = [
    { title: "편성표 관리", content: <div>편성표 관리 내용</div> },
    {
      title: "화상 시스템 운영 관리",
      content: <div>화상 시스템 운영 관리 내용</div>,
    },
    { title: "CMS", content: <div>CMS 내용</div> },
    { title: "편의기능 관리", content: <div>편의기능 관리 내용</div> },
    {
      title: "외부 메시지 회원 관리",
      content: <div>외부 메시지 회원 관리 내용</div>,
    },
    {
      title: "셋톱박스 기기 동작 현황",
      content: <div>셋톱박스 기기 동작 현황 내용</div>,
    },
    { title: "이용량", content: <div>이용량 내용</div> },
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  return (
    <Frame
      title={"양방향 화상회의 시스템 운영 관리"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default VideoConference;
