import React, { useState } from "react";
import Frame from "../components/common/Frame";

const Settings = () => {
  const subMenu = [
    { title: "조직 관리", content: <div>조직 관리 내용</div> },
    { title: "메뉴 관리", content: <div>메뉴 관리 내용</div> },
    { title: "권한관리", content: <div>권한관리 내용</div> },
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  return (
    <Frame
      title={"설정 관리"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default Settings;
