import React from "react";
import { Col, Row } from "react-bootstrap";
import "./TabMenu.scss";

const TabMenu = ({ subMenu, activeTab, onTabSelect }) => {
  return (
    <Row className="mb-4 py-1 tab-menu">
      <Col className="d-flex justify-content-start gap-5 px-md-4 px-lg-5">
        {subMenu?.map((sub, idx) => (
          <button
            key={idx}
            className={`fs-5 ${
              activeTab === sub.title
                ? "custom-tab-btn"
                : "btn btn-outline-secondary border-0"
            } `}
            onClick={() => onTabSelect(sub.title)}
          >
            {sub.title}
          </button>
        ))}
      </Col>
    </Row>
  );
};

export default TabMenu;
